<template>
	<v-card width="auto" class="d-inline-block">
		<v-form ref="form" class="d-flex" lazy-validation>
			<v-card :width="showSecond ? 290: 220" flat>
				<div style="height: 48px">
					<v-subheader>{{ title }}</v-subheader>
				</div>
				<v-card-title class="d-flex flex-nowrap justify-space-between align-start pt-0">
					<v-text-field
							ref="hour"
							v-model="hour"
							class="text-h4"
							:class="currFocus === 1 && 'discolor'"
							autocomplete="off"
							maxlength="2"
							hint="时"
							:rules="[
									v => !!v || '必填',
									v => v < 25 || '错误'
							]"
							:background-color="currFocus === 1 ? 'primary' :'rgba(180,180,180,.3)'"
							@focus="currFocus = 1"
							@blur="bindHour"
							persistent-hint
							flat
							solo
					></v-text-field>
					<span class="text-h4 mx-1 my-1">:</span>
					<v-text-field
							ref="minute"
							class="text-h4"
							:class="currFocus === 2 && 'discolor'"
							v-model="minute"
							autocomplete="off"
							maxlength="2"
							hint="分"
							:rules="[
									v => !!v || '必填',
									v => v < 60 || '错误'
							]"
							:background-color="currFocus === 2 ? 'primary' : 'rgba(180,180,180,.3)'"
							@focus="currFocus = 2"
							@blur="bindMinute"
							persistent-hint
							flat
							solo
					></v-text-field>
					<span class="text-h4 mx-1 my-1" v-if="showSecond">:</span>
					<v-text-field
							v-if="showSecond"
							ref="second"
							v-model="second"
							class="text-h4"
							:class="currFocus === 3 && 'discolor'"
							autocomplete="off"
							maxlength="2"
							hint="秒"
							:rules="[
									v => !!v || '必填',
									v => v < 60 || '错误'
							]"
							:background-color="currFocus === 3 ? 'primary' : 'rgba(180,180,180,.3)'"
							@focus="currFocus = 3"
							@blur="bindSecond"
							persistent-hint
							flat
							solo
					></v-text-field>
				</v-card-title>
			</v-card>
		</v-form>
	</v-card>
</template>

<script>
export default {
	name: 'timePicker',
	props: {
		title: {
			type: String,
			default: '开始时间'
		},
		value: {
			type: String,
			default: ''
		},
		max: {
			type: String,
			default: ''
		},
		min: {
			type: String,
			default: ''
		},
		showSecond: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			hour: '',
			minute: '',
			second: '',
			time: '',
			currFocus: '',
			maxTime: '',
			minTime: ''
		}
	},
	mounted() {
		!!this.value && this.init();
		// 监听鼠标滚动事件
		this.$refs.hour.$el.addEventListener('mousewheel', this.hourMouseWheel, false);
		this.$refs.minute.$el.addEventListener('mousewheel', this.minuteMouseWheel, false);
		if (this.showSecond) {
			this.$refs.second.$el.addEventListener('mousewheel', this.secondMouseWheel, false);
		}
	},
	methods: {
		hourMouseWheel(e) {
			this.mouseWheel(e, this.hour, 24, 0);
		},
		minuteMouseWheel(e) {
			this.mouseWheel(e, this.minute, 59, 1);
		},
		secondMouseWheel(e) {
			this.mouseWheel(e, this.second, 59, 2);
		},
		mouseWheel(e, value, limit, index) {
			if (e.wheelDelta) {
				let v = parseInt(value);
				if (e.wheelDelta > 0) {
					// 向上滚动
					if (v < limit && this.checkMaxMin(v, index)) {
            if (!(index > 0 && this.hour === 24)) {
              v++;
              value = this.formatter(v);
            }
					}
				}
				// 向下滚动
				else if (e.wheelDelta < 0) {
					if (v > 0 && this.checkMaxMin(v, index, true)) {
						v--;
						value = this.formatter(v);
					}
				}
				switch (index) {
					case 0:
						this.hour = value;
            if (this.hour === 24) {
              this.minute = '00'
            }
						break;
					case 1:
						this.minute = value;
						break;
					case 2:
						this.second = value;
						break;
					default:
						break;
				}
			}
		},
		init() {
			if (this.value) {
				const array = this.value.split(':');
				this.hour = this.formatter(array[0]);
				this.minute = this.formatter(array[1]);
				if (this.showSecond) {
					this.second = this.formatter(array[2]);
				}
			}
		},
		// 校验大小
		checkMaxMin(thisValue, index, reduce) {
			let checkValue = this.min ? this.min : this.max;
			const start = !!this.max;
			if (start && reduce) {
				return true;
			}
			if (!start && !reduce) {
				return true;
			}
			if (!this.globalMethods.isEmpty(checkValue) && typeof (thisValue) == 'number') {
				let array = checkValue.split(":");
				if (index === 1 && array[0] !== this.time.split(":")[0]) {
					return true;
				}
				if (index === 2) {
					return true;
				}
				const c = parseInt(array[index]);
				return start ? thisValue < c : thisValue > c;
			}
			return false;
		},
		formatter(v) {
			const value = parseInt(v);
			return value < 10 ? '0' + value : value;
		},
		bindHour() {
			this.hour = this.formatter(this.hour);
			this.getTime();
		},
		bindMinute() {
			this.minute = this.formatter(this.minute);
			this.getTime();
		},
		bindSecond() {
			this.second = this.formatter(this.second);
			this.getTime();
		},
		getTime() {
			this.time = this.hour + ':' + this.minute + (this.showSecond ? (':' + this.second) : '');
		},
		setCurrentTime(v) {
			if (v) {
				this.getTime();
				this.$emit('currentValue', this.time);
			}
		}
	},
	watch: {
		hour: {
			handler(v) {
				this.setCurrentTime(v);
			}
		},
		minute: {
			handler(v) {
				this.setCurrentTime(v);
			}
		},
		second: {
			handler(v) {
				this.setCurrentTime(v);
			}
		}
	}
}
</script>

<style scoped lang="scss">
::v-deep {
	.discolor input {
		color: #ffffff !important;
	}
}
</style>
