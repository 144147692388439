var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"d-inline-block",attrs:{"width":"auto"}},[_c('v-form',{ref:"form",staticClass:"d-flex",attrs:{"lazy-validation":""}},[_c('v-card',{attrs:{"width":_vm.showSecond ? 290: 220,"flat":""}},[_c('div',{staticStyle:{"height":"48px"}},[_c('v-subheader',[_vm._v(_vm._s(_vm.title))])],1),_c('v-card-title',{staticClass:"d-flex flex-nowrap justify-space-between align-start pt-0"},[_c('v-text-field',{ref:"hour",staticClass:"text-h4",class:_vm.currFocus === 1 && 'discolor',attrs:{"autocomplete":"off","maxlength":"2","hint":"时","rules":[
								function (v) { return !!v || '必填'; },
								function (v) { return v < 25 || '错误'; }
						],"background-color":_vm.currFocus === 1 ? 'primary' :'rgba(180,180,180,.3)',"persistent-hint":"","flat":"","solo":""},on:{"focus":function($event){_vm.currFocus = 1},"blur":_vm.bindHour},model:{value:(_vm.hour),callback:function ($$v) {_vm.hour=$$v},expression:"hour"}}),_c('span',{staticClass:"text-h4 mx-1 my-1"},[_vm._v(":")]),_c('v-text-field',{ref:"minute",staticClass:"text-h4",class:_vm.currFocus === 2 && 'discolor',attrs:{"autocomplete":"off","maxlength":"2","hint":"分","rules":[
								function (v) { return !!v || '必填'; },
								function (v) { return v < 60 || '错误'; }
						],"background-color":_vm.currFocus === 2 ? 'primary' : 'rgba(180,180,180,.3)',"persistent-hint":"","flat":"","solo":""},on:{"focus":function($event){_vm.currFocus = 2},"blur":_vm.bindMinute},model:{value:(_vm.minute),callback:function ($$v) {_vm.minute=$$v},expression:"minute"}}),(_vm.showSecond)?_c('span',{staticClass:"text-h4 mx-1 my-1"},[_vm._v(":")]):_vm._e(),(_vm.showSecond)?_c('v-text-field',{ref:"second",staticClass:"text-h4",class:_vm.currFocus === 3 && 'discolor',attrs:{"autocomplete":"off","maxlength":"2","hint":"秒","rules":[
								function (v) { return !!v || '必填'; },
								function (v) { return v < 60 || '错误'; }
						],"background-color":_vm.currFocus === 3 ? 'primary' : 'rgba(180,180,180,.3)',"persistent-hint":"","flat":"","solo":""},on:{"focus":function($event){_vm.currFocus = 3},"blur":_vm.bindSecond},model:{value:(_vm.second),callback:function ($$v) {_vm.second=$$v},expression:"second"}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }